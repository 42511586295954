import "es6-symbol/implement";
import axios from "axios";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { User } from "../redux/actions/index";
import store from "../redux/store";

const singleton = Symbol();
const singletonEnforcer = Symbol();

export default class Http {
  /**
   * @constructor
   */
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error("Cannot construct singleton");
    }
    this.client = axios.create({
      baseURL: process.env.SITE_URL,
      responseType: "json",
    });
    this.client.interceptors.request.use(
      (config) => {
        store.dispatch(showLoading());
        return config;
      },
      (error) => {
        store.dispatch(hideLoading());
        return Promise.reject(error);
      }
    );
    this.client.interceptors.response.use(
      (response) => {
        store.dispatch(hideLoading());
        return response;
      },
      (error) => {
        /**
         * @todo
         * implement token refresh when expired
         */
        if (error.response && error.response.status === 401) {
          //If token expired
          store.dispatch(User.logout());
          this.sharedInstance.resetAxiosHeaders();
          window.location.reload();
        }
        store.dispatch(hideLoading());
        return Promise.reject(error);
      }
    );
  }

  resetAxiosHeaders() {
    delete axios.defaults.headers.common["Authorization"];
  }

  /**
   * Singleton instance caller
   * @return {Http}
   */
  static get sharedInstance() {
    if (!this[singleton]) {
      this[singleton] = new Http(singletonEnforcer);
    }
    return this[singleton];
  }

  /**
   * Returns axios instance
   * @return {axios}
   */
  getClient() {
    return this.client;
  }

  /**
   * Applys auth headers to axios
   * @retun {axios}
   */
  withAuth() {
    this.client.interceptors.request.use(
      (config) => {
        const state = store.getState();
        const token = state.user ? state.user.access_token.accessToken : null;
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => Promise.reject(error)
    );
    return this.client;
  }

  /**
   * Download file from the server and trigger download in the browser
   * @param {string} url - The API endpoint for file download
   * @param {string} filename - The name for the downloaded file
   * @return {Promise<void>}
   */
  async downloadFile(url, filename) {
    try {
      store.dispatch(showLoading());

      // Make the request for file download
      const response = await this.client.get(url, {
        responseType: "blob", // Ensure response is a Blob (binary)
      });

      // Create a URL for the Blob
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", filename); // Set the file name for download

      // Append to the body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.remove();
      window.URL.revokeObjectURL(fileURL);

      store.dispatch(hideLoading());
    } catch (error) {
      store.dispatch(hideLoading());
      console.error("Error downloading file:", error);
    }
  }
}
