import {
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import { BlueprintEditor, FiltersBlueprints } from "../../../redux/actions";
import { connectWithStore } from "../../../services/redux";
import { isMobileDevice } from "../../../services/mobile";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import FilterListIcon from "@material-ui/icons/FilterList";
import { getElementSize, getUnit, getUnitSize } from "./utils";
import BlueprintFilters from "./filters";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import LayerRow from "./layer_row";
import MeasurePanel from "./measure_panel";

const useStyles = makeStyles((theme) => ({
  layerPanel: {
    width: isMobileDevice() ? "100%" : 300,
    height: isMobileDevice() ? "90%" : "100%",
    bottom: isMobileDevice() ? "55px" : 0,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  content: {
    overflow: "auto",
  },
  footer: {
    position: "fixed",
    bottom: 0,
    textAlign: "center",
    padding: 10,
    width: isMobileDevice() ? "100%" : 300,
  },
  header: {
    padding: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 2,
  },
  buttonsRow: {
    borderBottom: "1px solid #F0F0F0",
  },
  activeFilters: {
    margin: 10,
    borderBottom: "1px solid #F0F0F0",
    maxWidth: 250,
  },
  chip: {
    margin: 10,
  },
}));

function BlueprintEditorLayerPanel({
  blueprintEditor,
  dispatch,
  settings,
  bluePrintsFilters,
  job,
  question,
}) {
  const { layerPanelOpen, showFilters, mode } = blueprintEditor;

  const {
    filterUser,
    filterDate,
    filterJobType,
    filterElement,
    filterStatus,
    filtersChanged,
    filterJobId,
  } = bluePrintsFilters;
  const classes = useStyles();

  const [blueprintElements, setBlueprintElements] = useState([]);
  useEffect(() => {
    const elements = [];
    if (
      !settings ||
      !settings.blueprintSettings ||
      !settings.blueprintSettings.elements
    ) {
      setBlueprintElements(elements);
    }
    const units = getUnit();
    const ids = blueprintEditor.elements.map((e) => e.id);
    blueprintEditor.jobs.forEach((job) => {
      job.elements
        .filter((elementFilter) => ids.includes(elementFilter._id))
        .forEach((el) => {
          const parsedData = JSON.parse(el.canvasData);
          const tools = settings.blueprintSettings.elements
            ? settings.blueprintSettings.elements.filter(
              (t) => t.id === el.elementId
            )
            : [];
          if (tools.length > 0) {
            const { width /* , height */ } = getElementSize(el.canvasData);
            const elementTarget = blueprintEditor.elements.filter(
              (e) => e.id === el._id
            );
            const targetId =
              elementTarget && elementTarget[0] && elementTarget[0].id;
            const shape = blueprintEditor.app.find("#" + targetId)[0];
            const comment = shape && shape.attrs ? shape.attrs.comment : "";
            const widthAsHeight =
              shape && shape.attrs && shape.attrs.widthAsHeight ? true : false;

            elements.push({
              blueprint: job,
              element: elementTarget[0],
              color: tools[0].color,
              name: tools[0].name,
              type: tools[0].type,
              created_at: el.created_at,
              comment,
              widthAsHeight,
              width: getUnitSize(shape, width),
            });
          } else {
            //exclude Arrow elements from side panel
            if (
              !(
                parsedData &&
                parsedData.className &&
                parsedData.className === "Arrow"
              )
            ) {
              elements.push({
                blueprint: job,
                element: el,
                color: { hex: "#000" },
                created_at: el.created_at,
                name: i18n.t("default:_REMOVED_ELEMENT"),
                type: -1,
                width: 0 + units,
              });
            }
          }
        });
    });
    setBlueprintElements(elements);
  }, [
    blueprintEditor.elements,
    blueprintEditor.jobs,
    settings.blueprintSettings,
    blueprintEditor.app,
    setBlueprintElements,
    settings,
  ]);

  const toggleFilters = () => {
    if (showFilters) {
      dispatch(BlueprintEditor.setShowFilters(false));
    } else {
      dispatch(BlueprintEditor.setShowFilters(true));
    }
  };

  const filterElements = (el) => {
    let showElement = true;

    if (filterUser.length > 0 && el.element && el.element.user) {
      const userIds = filterUser.map((user) => user.value);
      showElement = userIds.includes(el.element.user._id);
    }
    //try next filters only if showElement is still set to true
    if (filterDate.length > 0 && showElement && el.blueprint) {
      const elDate = moment(el.blueprint.created_at /* , "day" */);
      const startDate = filterDate && filterDate[0] && filterDate[0].from;
      const endDate = filterDate && filterDate[0] && filterDate[0].to;

      //check if element date is between from and to from filterDate
      showElement = elDate.isBetween(startDate, endDate);
    }
    if (
      filterJobType.length > 0 &&
      showElement &&
      el.blueprint &&
      el.blueprint.job &&
      el.blueprint.job.jobType
    ) {
      const jtIds = filterJobType.map((jt) => jt.value);
      showElement = jtIds.includes(el.blueprint.job.jobType._id);
    }
    if (filterElement.length > 0 && showElement) {
      const elNames = filterElement.map((el) => el.label);
      showElement = elNames.includes(el.name);
    }
    if (
      filterStatus.length > 0 &&
      showElement &&
      el.blueprint &&
      el.blueprint.job &&
      el.blueprint.job.status
    ) {
      const statuseValues = filterStatus.map((status) => status.value);
      showElement = statuseValues.includes(el.blueprint.job.status);
    }
    if (
      filterJobId.length > 0 &&
      showElement &&
      el.blueprint &&
      el.blueprint.job &&
      el.blueprint.job.ID
    ) {
      const jobIdValues = filterJobId.map((job) => job.value);
      showElement = jobIdValues.includes(el.blueprint.job.ID);
    }
    //if filterChanged and if showElement - make attr to visible, else set to visible - false
    if (filtersChanged && el.element) {
      const shape = blueprintEditor.app.find("#" + el.element.id)[0];
      if (showElement) {
        shape.show();
      } else {
        shape.hide();
      }
      blueprintEditor.app.batchDraw();
      dispatch(FiltersBlueprints.setFiltersChanged(false));
    }
    return showElement;
  };

  return (
    <Drawer
      classes={{ paper: classes.layerPanel }}
      anchor={isMobileDevice() ? "bottom" : "right"}
      open={layerPanelOpen}
      width={isMobileDevice() ? "100%" : "25%"}
      variant={"persistent"}
      onClose={() => dispatch(BlueprintEditor.closeLayerPanel())}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Grid container alignItems="center">
            <IconButton
              onClick={() => dispatch(BlueprintEditor.closeLayerPanel())}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle1">
              {i18n.t("default:_JOBS")}
            </Typography>
          </Grid>
          <Grid
            container
            justify="space-between"
            className={classes.buttonsRow}
          >
            <Grid item>
              <IconButton>
                <CheckCircleIcon color="primary" />
              </IconButton>
              <IconButton>
                <RemoveCircleIcon color="error" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => toggleFilters()}>
                <FilterListIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
        {(filterDate.length > 0 ||
          filterElement.length > 0 ||
          filterUser.length > 0 ||
          filterJobType.length > 0) &&
          !showFilters && (
            <Grid
              container
              alignItems="center"
              className={classes.activeFilters}
            >
              <Typography>{`Active filters:`}</Typography>
              {filterUser.length > 0 && (
                <Chip
                  className={classes.chip}
                  label={i18n.t("default:_USER")}
                  variant="outlined"
                />
              )}
              {filterElement.length > 0 && (
                <Chip
                  className={classes.chip}
                  label={i18n.t("default:_ELEMENT")}
                  variant="outlined"
                />
              )}
              {filterJobType.length > 0 && (
                <Chip
                  className={classes.chip}
                  label={i18n.t("default:_JOB_TYPE")}
                  variant="outlined"
                />
              )}
              {filterDate.length > 0 && (
                <Chip
                  className={classes.chip}
                  label={i18n.t("default:_DATE")}
                  variant="outlined"
                />
              )}
            </Grid>
          )}
        {showFilters && <BlueprintFilters />}

        {/* In set mode show measures panel */}
        {mode === "SET" && <MeasurePanel />}
        <div className={classes.content}>
          {blueprintElements
            .filter(filterElements)
            .sort((a, b) => {
              return moment.utc(b.created_at).diff(moment.utc(a.created_at));
            })
            .map((bp, index) => {
              return (
                <LayerRow
                  key={index}
                  type={bp.type}
                  color={bp.color}
                  label={bp.name}
                  width={bp.width}
                  blueprint={bp.blueprint}
                  element={bp.element}
                  job={job}
                  comment={bp.comment}
                  question={question}
                  widthAsHeight={bp.widthAsHeight}
                />
              )
            })}
        </div>
      </div>
    </Drawer>
  );
}

export default connectWithStore(BlueprintEditorLayerPanel, [
  "blueprintEditor",
  "settings",
  "bluePrintsFilters",
]);
