import React, { useEffect, useRef } from "react";
import Konva from "konva";
import { connectWithStore } from "../../../../services/redux";
import { MOVE_TOOL, POLYGON_OPEN, POLYGON_TOOL, SHAPE } from "../constants";
import { BlueprintEditor } from "../../../../redux/actions";
import store from "../../../../redux/store";
import {
  addNewElementToJob,
  getRelativePointerPosition,
  canUserEditElement,
  removeTransformers,
  setScaledPoints,
  removeShape,
} from "../utils";
import { v1 as uuid } from "uuid";
import { batch } from "react-redux";
import i18n from "../../../../i18n";
import { isMobileDevice } from "../../../../services/mobile";

let lastLine;
let canvasData;
let isPaint = false;

const ELEMENT_NAME = "Polygon";

function PolygonTool({ blueprintEditor, dispatch, job, question }) {
  const { app } = blueprintEditor;
  let transformer = useRef();
  const points = useRef([]);
  const lastLineId = useRef(null);

  //close polygon logic: on desktop the check is on each click and on mobile the check is on each touch end
  const finishPolygonDrawing = (
    previousPoints,
    initialX,
    initialY,
    profile,
    blueprintEditor,
    skipToolSwitch = false
  ) => {
    const { tool, layer, mode } = blueprintEditor;
    if (tool.type !== POLYGON_OPEN) {
      points.current = previousPoints.concat([initialX, initialY]);
      lastLine.closed(true);
      lastLine.points(points.current);
      if (!tool.outlined) {
        lastLine.fill(tool.color);
      }
      lastLine.hitStrokeWidth(0);
      lastLine.shadowForStrokeEnabled(false);
    } else {
      lastLine.hitStrokeWidth(10);
    }
    const updatedJobs = addNewElementToJob(
      canvasData,
      ELEMENT_NAME,
      tool,
      lastLine.getAttr("id"),
      blueprintEditor
    );
    layer.batchDraw();
    isPaint = false;
    canvasData.poins = points.current;

    batch(() => {
      dispatch(BlueprintEditor.setJobs(updatedJobs));
      if (!skipToolSwitch) {
        dispatch(
          BlueprintEditor.addElement({
            id: lastLine.getAttr("id"),
            elementId: tool.element.id,
            user: profile,
            job,
          })
        );
        dispatch(
          BlueprintEditor.changeTool({
            type: MOVE_TOOL,
            color: "#000",
            name: i18n.t("default:_MOVE_TOOL"),
          })
        );
        dispatch(
          BlueprintEditor.updateHistory(tool && tool.element && tool.element.id)
        );
      }
    });

    lastLine.on("dragstart", () => {
      if (
        mode !== "EDIT" ||
        !(
          transformer &&
          transformer.current &&
          transformer.current._nodes &&
          transformer.current._nodes.length > 0
        )
      ) {
        lastLine.stopDrag();
      } else {
        dispatch(BlueprintEditor.setSelectedElement(lastLine));
      }
    });
    lastLine.on("transformend", () => {
      setScaledPoints(lastLine);
      dispatch(
        BlueprintEditor.updateElement({
          id: lastLine.getAttr("id"),
          elementId: tool.element.id,
          user: profile,
          visible: true,
          job,
        })
      );
      dispatch(BlueprintEditor.updateHistory());
    });
    lastLine.on("dragend", () => {
      dispatch(
        BlueprintEditor.updateElement({
          id: lastLine.getAttr("id"),
          elementId: tool.element.id,
          user: profile,
          visible: true,
          job,
        })
      );
      dispatch(BlueprintEditor.updateHistory());
    });
    blueprintEditor.app.draggable(true);
  };

  useEffect(() => {
    if (app) {
      app.on("mousedown touchstart", (e) => {
        if(window.isBluprintSpaceBarHeld){
          return;
        }
        const state = store.getState();
        const { blueprintEditor, user } = state;
        const {
          tool,
          layer,
          mode,
          selectedElement,
          openPolygonSides,
        } = blueprintEditor;
        const { profile } = user;

        if (mode !== "EDIT") {
          return;
        }
        if (
          !tool ||
          (tool &&
            tool.type !== MOVE_TOOL &&
            tool.type !== POLYGON_TOOL &&
            tool.type !== POLYGON_OPEN)
        ) {
          return;
        }
        // enter drawing mode
        if (
          (e.target === app || e.target.id() === "blueprint" || isPaint || (e.target.parent && e.target.parent.attrs.name && e.target.parent.attrs.name.includes("Layer"))) &&
          tool &&
          (tool.type === POLYGON_TOOL || tool.type === POLYGON_OPEN)
        ) {
          var pos = getRelativePointerPosition(app);

          // STEP 1
          // if is first click / isPaint is false /
          // create line element
          if (
            !isPaint ||
            (isPaint && points.current && points.current.length === 0)
          ) {
            const id = uuid();
            canvasData = {
              stroke: tool.color,
              strokeWidth:
                tool.width > 10 || tool.element.type === SHAPE.POLYGON_FILLED
                  ? tool.width
                  : 10,
              points: [pos.x, pos.y],
              draggable: true,
              name: ELEMENT_NAME,
              closed: false,
              id,
            };
            lastLine = new Konva.Line(canvasData);
            lastLineId.current = lastLine.getAttr('id');
            layer.add(lastLine);
            layer.batchDraw();
            points.current = [pos.x, pos.y];
            isPaint = true;
          } else if (isPaint) {
            const previousPoints = points.current;

            // FINAL STEP
            // if initial points are equal or near to the start point
            // end drawing and store the figure in redux and change tool
            const initialX = previousPoints[0];
            const initialY = previousPoints[1];
            if (
              !isMobileDevice() &&
              initialX >= pos.x - 10 &&
              initialX <= pos.x + 10 &&
              initialY >= pos.y - 10 &&
              initialY <= pos.y + 10 &&
              points.current.length > 3 &&
              tool.type === POLYGON_TOOL
            ) {
              finishPolygonDrawing(
                previousPoints,
                initialX,
                initialY,
                profile,
                blueprintEditor
              );
              // STEPS INBETWEEN
              // if is not first click and is not final click
              // add points to line and continue drawing
            } else {
              if (!isMobileDevice() && tool.type === POLYGON_OPEN) {
                points.current = previousPoints.concat([pos.x, pos.y]);

                //IF IS POLYGON OPEN AND ALL SIDES ARE ALREADY DRAWN FINISH DRAWING
                const drawnSides = (points.current.length - 2) / 2;
                if (drawnSides === openPolygonSides) {
                  finishPolygonDrawing(
                    null,
                    null,
                    null,
                    profile,
                    blueprintEditor
                  );
                  dispatch(BlueprintEditor.setOpenPolygonSides(1));
                }
              }
            }
          }
        }

        // select element
        if (
          e.target &&
          e.target.attrs &&
          e.target.attrs.name === ELEMENT_NAME &&
          !isPaint
        ) {
          const selectedElementArr = blueprintEditor.elements.filter(
            (el) => el.id === e.target.attrs.id
          );
          const elementJob =
            selectedElementArr.length > 0 ? selectedElementArr[0].job : null;
          if (!canUserEditElement(elementJob, job, question)) {
            return;
          }
          if (
            (!transformer.current ||
              (transformer.current &&
                transformer.current._nodes &&
                transformer.current._nodes.length === 0)) &&
            !selectedElement
          ) {
            removeTransformers();
            dispatch(BlueprintEditor.selectLine(e.target));
            if (!transformer.current) {
              transformer.current = new Konva.Transformer({
                nodes: [e.target],
                ignoreStroke: true,
              });
            } else {
              transformer.current.nodes([e.target]);
            }
            layer.add(transformer.current);
            layer.batchDraw();
          }
          return;
        }

        //deselect element only if click on Image
        if (
          selectedElement &&
          e.target &&
          e.target.attrs &&
          e.target.attrs.image &&
          !e.target.attrs.name
        ) {
          dispatch(BlueprintEditor.deselectElement());
          removeTransformers();
          if (
            transformer &&
            transformer.current &&
            transformer.current._nodes &&
            transformer.current._nodes.length > 0
          ) {
            transformer.current.nodes([]);
            layer.batchDraw();
          }
          return;
        }
      });

      // and core function - drawing
      app.on("mousemove touchmove", () => {
        if(window.isBluprintSpaceBarHeld){
          return;
        }
        const state = store.getState();
        const { blueprintEditor } = state;
        const { tool, layer } = blueprintEditor;
        if (tool && tool.type !== POLYGON_TOOL && tool.type !== POLYGON_OPEN) {
          return;
        }
        blueprintEditor.app.draggable(false);
        if (!isPaint) {
          return;
        }

        const pos = getRelativePointerPosition(app);
        const currentPoints = points.current;
        const newPoints = currentPoints.concat([pos.x, pos.y]);

        lastLine.points(newPoints);
        layer.batchDraw();
      });

      app.on("mouseup touchend", () => {
        if(window.isBluprintSpaceBarHeld){
          return;
        }
        const state = store.getState();
        const { blueprintEditor, user } = state;
        const { tool, openPolygonSides } = blueprintEditor;
        const { profile } = user;
        if (
          !tool ||
          (tool && tool.type !== POLYGON_TOOL && tool.type !== POLYGON_OPEN)
        ) {
          return;
        }

        if (isPaint) {
          var pos = getRelativePointerPosition(app);
          const previousPoints = points.current;
          const initialX = previousPoints[0];
          const initialY = previousPoints[1];
          if (
            isMobileDevice() &&
            initialX >= pos.x - 10 &&
            initialX <= pos.x + 10 &&
            initialY >= pos.y - 10 &&
            initialY <= pos.y + 10 &&
            points.current.length > 3
          ) {
            finishPolygonDrawing(
              previousPoints,
              initialX,
              initialY,
              profile,
              blueprintEditor
            );
          } else {
            if (tool.type === POLYGON_OPEN && !isMobileDevice()) {
              return;
            }
            //add points only if are different than the initial ones (avoid doubles)
            if (
              points.current.length > 2 ||
              (points.current.length === 2 &&
                (pos.x !== points.current[0] || pos.y !== points.current[1]))
            ) {
              points.current = previousPoints.concat([pos.x, pos.y]);
            }
            const drawnSides = (points.current.length - 2) / 2;
            if (tool.type === POLYGON_OPEN && drawnSides === openPolygonSides) {
              finishPolygonDrawing(null, null, null, profile, blueprintEditor);
              dispatch(BlueprintEditor.setOpenPolygonSides(1));
            }
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [app, dispatch]);

  useEffect(() => {
    const removePolygon = () => {
      const state = store.getState();
      const { blueprintEditor, user } = state;
      const elId = lastLineId.current;
      finishPolygonDrawing([], null, null, user.profile, blueprintEditor, true);
      const shape = blueprintEditor.app.find("#" + elId)[0];
      if (shape) {
        removeShape(shape);
      }
      lastLineId.current = null;
    };
    document.addEventListener('cancelPolygonDrawing', removePolygon);
    return () => {
      document.removeEventListener('cancelPolygonDrawing', removePolygon);
    }
    // eslint-disable-next-line
  }, [lastLineId]);
  return <div />;
}

export default connectWithStore(PolygonTool, ["blueprintEditor"]);
