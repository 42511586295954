import React, { useState, useCallback, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Joyride from "react-joyride";
import { connectWithStore } from "../../../services/redux";
import i18n from "../../../i18n";

const step2 = require("../../../assets/img/bp_measure_step2.gif");
const step3 = require("../../../assets/img/bp_measure_step3.gif");

const useStyles = makeStyles((theme) => ({
  leftPoint: {
    position: "absolute",
    left: 70,
    top: "50%",
  },
  topPoint: {
    position: "absolute",
    left: "50%",
    top: 70,
  },
}));

function MeasureToolHeight() {
  return (
    <div>
      <h4 dangerouslySetInnerHTML={{ __html: i18n.t("default:_MEASURE_TOOL_SET_HEIGHT") }}></h4>
      <img src={step2} alt="set measure height" style={{ width: 200, height: 250, objectFit: 'contains' }} />
    </div>
  );
}

function MeasureToolWidth() {
  return (
    <div>
      <h4 dangerouslySetInnerHTML={{ __html: i18n.t("default:_MEASURE_TOOL_SET_WIDTH") }}></h4>
      <img src={step3} alt="set measure width" style={{ width: 250, height: 200, objectFit: 'contains' }} />
    </div>
  );
}

function WelcomeWizard({ blueprintEditor, updateFileProp }) {
  const classes = useStyles();
  const { measurements, fileId, measureIntroDisplayed } = blueprintEditor;
  const [startWelcomeWizard, setStartWelcomeWizard] = useState(false);
  const steps = [
    {
      content: <h4>{i18n.t("default:_MEASURE_TOOL_INTRO")}</h4>,
      locale: {
        skip: <strong aria-label="skip">{i18n.t("default:_SKIP")}</strong>,
      },
      placement: "center",
      target: "body",
    },
    {
      content: <h4>{i18n.t("default:_MEASURE_TOOL_SELECT")}</h4>,
      floaterProps: {
        disableAnimation: true,
      },
      //spotlightPadding: 20,
      target: ".add_icon",
      placement: "right-end",
    },
    {
      content: <MeasureToolHeight />,
      floaterProps: {
        disableAnimation: true,
      },
      target: ".add_icon",
      placement: "right-end",
    },
    {
      content: <MeasureToolWidth />,
      floaterProps: {
        disableAnimation: true,
      },
      target: ".add_icon",
      placement: "right-end",
    },
  ];

  const checkForMeasures = useCallback(() => {
    //show intro only if is for the first time or if button is pressed
    if (!measureIntroDisplayed) {
      setStartWelcomeWizard(true);
      updateFileProp("measureIntroDisplayed", true, fileId);
    } else {
      setStartWelcomeWizard(false);
    }
    // eslint-disable-next-line
  }, [measurements, fileId]);

  useEffect(() => {
    checkForMeasures();
  }, [checkForMeasures]);

  return (
    <>
      <Joyride
        continuous={true}
        run={startWelcomeWizard}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Grid className={`left-point ${classes.leftPoint}`} />
      <Grid className={`top-point ${classes.topPoint}`} />
    </>
  );
}

export default connectWithStore(WelcomeWizard, ["blueprintEditor"]);
