import Konva from "konva";
import React, { useEffect, useRef } from "react";
import i18n from "../../../../i18n";
import { BlueprintEditor } from "../../../../redux/actions";
import store from "../../../../redux/store";
import { connectWithStore } from "../../../../services/redux";
import { MEASURE_TOOL_VERTICAL, MEASURE_TYPE, MOVE_TOOL } from "../constants";
import {
  getRelativePointerPosition,
  getScaledHeight,
  removeTransformers,
  selectOrDeselectMeasure,
} from "../utils";
import { v1 as uuid } from "uuid";

function MeasureToolVertical({ blueprintEditor, dispatch, user }) {
  const { app, layer } = blueprintEditor;
  const x1 = useRef(0);
  const y1 = useRef(0);
  const selectionHeightLine = useRef(
    new Konva.Line({
      stroke: "red",
      strokeWidth: 2,
      visible: false,
      points: [0, 0, 0, 0, 0, 0],
      pointerAtBeginning: true,
      pointerAtEnding: true,
      id: "",
      name: "Arrow",
      mtype: MEASURE_TYPE.HEIGHT,
      draggable: true,
      hitStrokeWidth: 15,
    })
  );
  let transformer = useRef();

  useEffect(() => {
    if (!app) return;
    app.on("mousedown touchstart", function (e) {
      const state = store.getState();
      const { blueprintEditor } = state;
      const { app, tool, measurements } = blueprintEditor;
      // do nothing if measure tool is not selected
      if (tool && tool.type !== MEASURE_TOOL_VERTICAL) {
        return;
      }
      if (
        !measurements ||
        (measurements && !measurements.arrows) ||
        (measurements && measurements.arrows && !measurements.arrows.height)
      ) {
        app.draggable(false);
        layer.add(selectionHeightLine.current);
        const relativePos = getRelativePointerPosition(app);
        const heightId = uuid();
        x1.current = relativePos.x;
        y1.current = relativePos.y;
        selectionHeightLine.current.visible(true);
        selectionHeightLine.current.points([
          relativePos.x,
          relativePos.y,
          relativePos.x,
          relativePos.y,
          relativePos.x,
          relativePos.y,
        ]);
        selectionHeightLine.current.zIndex(9998);
        selectionHeightLine.current.id(heightId);
        dispatch(
          BlueprintEditor.addElement({
            id: heightId,
            elementId: `m${uuid()}`,
            user: user.profile,
            type: MEASURE_TYPE.HEIGHT,
          })
        );
        dispatch(BlueprintEditor.setIsMeasuring(true));
      }
    });

    //select or deselect element
    app.on("click tap", function (e) {
      const state = store.getState();
      const { blueprintEditor } = state;
      const { selectedElement, mode } = blueprintEditor;
      if (mode !== "SET") {
        return;
      }
      if (
        selectedElement &&
        selectedElement.attrs &&
        selectedElement.attrs.mtype &&
        selectedElement.attrs.mtype === MEASURE_TYPE.HEIGHT &&
        (!transformer.current ||
          (transformer.current &&
            transformer.current._nodes &&
            transformer.current._nodes.length === 0))
      ) {
        removeTransformers();
        dispatch(BlueprintEditor.setSelectedElement(null));
      } else {
        selectOrDeselectMeasure(transformer, dispatch, e, MEASURE_TYPE.HEIGHT);
      }
    });

    app.on("mousemove touchmove", function () {
      const state = store.getState();
      const { blueprintEditor } = state;
      const { app, tool, layer, measurements, isMeasuring } = blueprintEditor;
      // do nothing if we didn't start selection
      if (
        !tool ||
        (tool && tool.type !== MEASURE_TOOL_VERTICAL) ||
        !isMeasuring
      )
        return;
      const relativePos = getRelativePointerPosition(app);
      if (
        !measurements ||
        (measurements && !measurements.pixels) ||
        (measurements &&
          measurements.pixels &&
          measurements.pixels.height === 0)
      ) {
        selectionHeightLine.current.points([
          x1.current,
          y1.current,
          x1.current,
          relativePos.y,
        ]);
        layer.batchDraw();
      }
    });
    app.on("mouseup touchend", function () {
      const state = store.getState();
      const { blueprintEditor } = state;
      const {
        /* app,  */ tool,
        layer,
        measurements,
        isMeasuring,
        //pdfInfo,
      } = blueprintEditor;
      const points = selectionHeightLine.current.getAttr("points");
      const height = points[3] - points[1];
      if (
        !selectionHeightLine.current.visible() ||
        !tool ||
        tool.type !== MEASURE_TOOL_VERTICAL
      ) {
        return;
      }
      let currentMeasurements =
        measurements && measurements.pixels && measurements.metrics
          ? { ...measurements }
          : {
              pixels: {
                width: 0,
                height: 0,
              },
              metrics: {
                width: 0,
                height: 0,
              },
              arrows: {
                width: null,
                height: null,
              },
            };

      if (x1.current > 0 && height > 10 && isMeasuring) {
        currentMeasurements.pixels.height = height;

        dispatch(BlueprintEditor.setShowHeightInput(true));

        // open height input after drawing to enter meters
        if (currentMeasurements.pixels.height !== 0) {
          dispatch(BlueprintEditor.setMeasurements(currentMeasurements));
        }

        // update visibility in timeout, so we can check it in click event
        setTimeout(() => {
          console.log("attach transformer");
          //attach transformer to the measure
          transformer.current = new Konva.Transformer({
            nodes: [selectionHeightLine.current],
          });
          selectionHeightLine.current.on("transformend", () => {
            const scaledHeight = getScaledHeight(selectionHeightLine.current);
            currentMeasurements.pixels.height = scaledHeight;
            dispatch(BlueprintEditor.setMeasurements(currentMeasurements));
          });
          dispatch(
            BlueprintEditor.setSelectedElement(selectionHeightLine.current)
          );
          layer.add(transformer.current);
          layer.batchDraw();

          dispatch(
            BlueprintEditor.changeTool({
              type: MOVE_TOOL,
              color: "#000",
              name: i18n.t("default:_MOVE_TOOL"),
            })
          );
          dispatch(BlueprintEditor.setIsMeasuring(false));
          if (
            currentMeasurements.pixels.height !== 0 &&
            currentMeasurements.pixels.width !== 0
          ) {
            app.draggable(true);
          }
        });
      } else {
        dispatch(BlueprintEditor.setIsMeasuring(false));
        //cleareMeasurement();
      }
    });
    // eslint-disable-next-line
  }, [app, dispatch, layer, user.profile]);
  return <div />;
}

export default connectWithStore(MeasureToolVertical, [
  "blueprintEditor",
  "user",
]);
