import React from "react";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { connectWithStore } from "../../../../services/redux";
import { BlueprintEditor } from "../../../../redux/actions";

function FloatingInput({ blueprintEditor, dispatch, onClose, type }) {
  const { measurements } = blueprintEditor;

  const handleChange = (unit) => {
    if (measurements) {
      const currentMeasurements = { ...measurements };
      currentMeasurements.metrics[type] = +unit;
      dispatch(BlueprintEditor.setMeasurements(currentMeasurements));
    }
  };

  return (
    <>
      <Grid container item>
        <FormControl variant="standard" sx={{ m: 1, mt: 3, width: "25ch" }}>
          <Input
            id={`measure-${type}`}
            value={
              measurements && measurements.metrics && measurements.metrics[type]
                ? measurements.metrics[
                    type
                  ].toString() /* .replace(/^0+/, '') */
                : 0
            }
            onChange={(e) => handleChange(e.target.value)}
            endAdornment={<InputAdornment position="end">M</InputAdornment>}
            type="number"
            inputProps={{
              "aria-label": type,
            }}
          />
          <FormHelperText id="standard-weight-helper-text">{`${type} - ${
            measurements && measurements.pixels
              ? Math.round(measurements.pixels[type])
              : 0
          } pixels`}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid container item justify="flex-end">
        <Button
          color="primary"
          variant="outlined"
          disabled={!measurements}
          onClick={() => onClose()}
          size="small"
        >
          Set
        </Button>
      </Grid>
    </>
  );
}

export default connectWithStore(FloatingInput, ["blueprintEditor"]);
