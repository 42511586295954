import React, { useCallback, useEffect, useState } from "react";
import { AppBar, Grid, IconButton, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import i18n from "../../../i18n";
import { connectWithStore } from "../../../services/redux";
import { BlueprintEditor } from "../../../redux/actions";
import { ROLE } from "../../../services/roles";
import Konva from "konva";
import {
  getMarkings,
  getQuestionElements,
  updateElements,
} from "../../../services/api/blueprints";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from '@material-ui/icons/Save';
import store from "../../../redux/store";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import WidgetsIcon from "@material-ui/icons/Widgets";
import { CONSTRUCTOR_NAME, BLUEPRINT_ID } from "./constants";
import { getFileTarget } from "../../../services/api/file_upload";
import { pdfResize, getPdfPages } from "../../../services/api/file_upload";
import ReactTooltip from "react-tooltip";
import {
  areMeasuresSet,
  canUserEditElement,
  removeTransformers,
  canSet,
  drawMeasure,
  setScaledPoints,
  findShapeById,
} from "./utils";
import { isMobileDevice } from "../../../services/mobile";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
//import { v1 as uuid } from "uuid";
import { BigLoader } from "../../loader/big_loader";
import { attachInitialEvents } from "./utils_events";
import WarningIcon from '@material-ui/icons/Warning';
import { getCurrentJob } from "../../../services/api/jobs";
import WorkIcon from '@material-ui/icons/Work';
const TOP_TOOLBAR_HEIGHT = 55;
const MOBILE_WIDTH_OFFSET = 20;
const SIDEBAR_WIDTH = 200;
//const MOBILE_HEIGHT_OFFSET = 190;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "7%",
  },
  centered: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  }
}));

function BlueprintEditorTopbar({
  blueprintEditor,
  dispatch,
  user,
  job,
  question,
  submitQuestion,
  saveQuestion,
  nextQuestion,
  jobQuestions,
  filteredQuestions,
  settings,
  setTransform,
  updateFileProp,
  onCloseCallback
}) {
  const classes = useStyles();
  const { profile } = user;
  const { role } = profile;
  const { activeQuestion } = jobQuestions;
  const {
    fileName,
    imageRotation,
    imageGrayscale,
    filePage,
    mode,
    pdfInfo,
    fileId,
    isPdfResizing,
    showEditBpModal,
  } = blueprintEditor;
  const { imgSource, pages, size } = pdfInfo;

  const [markings, setMarkings] = useState(0);

  const getFilePages = async (id) => {
    const query = `pdf=${id}&get_sizes=true`;
    const response = await getPdfPages({ query });

    if (response && response.length > 0) {
      const pages = response[0];

      return pages;
    }

    return null;
  };

  const getPdfSize = (size) => {
    const stageHeight = window.innerHeight - TOP_TOOLBAR_HEIGHT;
    const stageWidth = isMobileDevice()
      ? window.innerWidth - MOBILE_WIDTH_OFFSET
      : window.innerWidth - SIDEBAR_WIDTH;
    let fitHeight = stageHeight;
    let ratio =
      size && size.dimensions && size.dimensions.height && size.dimensions.width
        ? size.dimensions.width / size.dimensions.height
        : 1;
    //set width according to width/height ratio
    let fitWidth = ratio !== 1 ? fitHeight * ratio : stageWidth;
    //resize height in case width is bigger than stage
    if (fitWidth > stageWidth) {
      fitWidth = stageWidth;
      ratio = stageWidth / fitWidth;
      fitHeight = fitHeight * ratio;
    }

    const isImagePortrait =
      imageRotation && imageRotation > 0
        ? (imageRotation / 90) % 2 === 1
        : false;
    if (isImagePortrait) {
      const rotatedWidth = fitWidth;
      const rotatedHeight = fitHeight;
      fitHeight = rotatedWidth;
      fitWidth = rotatedHeight;
      if (fitHeight < stageHeight) {
        ratio = stageHeight / fitHeight;
        fitHeight = stageHeight;
        fitWidth = fitWidth * ratio;
      }
    }
    const returnSize = {
      height: size && size.dimensions && size.dimensions.height,
      width: size && size.dimensions && size.dimensions.width,
      fitSize: {
        width: fitWidth,
        height: fitHeight,
      },
    };
    //reset transform wrapper so it fits the screen
    const initialXPosition = (returnSize.width - fitWidth) / 2;
    const initialScale = fitWidth / returnSize.width;
    setTransform(
      initialXPosition > 0 ? initialXPosition : 0,
      0,
      initialScale < 1 ? initialScale : 1,
      300,
      "easeOut"
    );
    return returnSize;
  };

  const updatePdfInfo = (response) => {
    const pages = response && response.children;
    const sizes = response && response.size;
    const selectedPage = pages && pages[filePage - 1];
    const selectedPageSize = sizes && sizes[filePage - 1];
    // set pages and all needed info below
    let updatedPdfInfo = { ...pdfInfo };
    updatedPdfInfo.imgSource = selectedPage;
    updatedPdfInfo.pages = pages && pages.length > 0 ? pages : [];
    updatedPdfInfo.totalPages = pages && pages.length > 1 ? pages.length : 1;
    updatedPdfInfo.size = getPdfSize(selectedPageSize);

    dispatch(BlueprintEditor.setPdfInfo(updatedPdfInfo));
  };

  const checkFile = useCallback(
    async () => {
      if (isPdfResizing || !fileId) {
        return;
      }
      const pages = await getFilePages(fileId);
      updatePdfInfo(pages);
    },
    // eslint-disable-next-line
    [filePage, fileId]
  );

  useEffect(
    () => {
      if (!pages && fileId) {
        //check if file is resized
        //  dispatch(showLoading());
        checkFile();
      }
    },
    // eslint-disable-next-line
    [pages, fileId]
  );

  const resizePdf = useCallback(async () => {
    dispatch(BlueprintEditor.setPdfResizing(true));
    const params = {
      id: fileId,
    };
    const resizeResponse = await pdfResize(params);
    if (resizeResponse.success) {
      const resizedFileId = resizeResponse.data;
      const pages = await getFilePages(resizedFileId);
      updatePdfInfo(pages);
      dispatch(BlueprintEditor.setPdfResizing(false));
    }
    // eslint-disable-next-line
  }, [dispatch, fileId]);

  useEffect(() => {
    if (!isPdfResizing && fileId && pages && pages.length === 0) {
      //dispatch(showLoading());
      resizePdf();
    }
  }, [isPdfResizing, resizePdf, fileId, pages, dispatch]);

  useEffect(() => {
    const data = {
      job: job._id
    }
    if (question) {
      data.question = question._id;
    }
    getMarkings(data).then((res) => {
      const tmp = res.reduce((acc, mark) => mark.elements.length > 0 ? acc + 1 : acc, 0);
      setMarkings(tmp);
    })
  }, [job, question])

  const canEdit = useCallback(() => {
    if (role === ROLE.SYSTEM_ADMIN) {
      return true;
    }
    if (!question) {
      return true;
    }
    return question.settings.permissions && question.settings.permissions[role];
  }, [role, question]);

  const fitToScreen = (onDone) => {
    const stage = blueprintEditor.app;
    // get layers
    const nodesLayer = stage.findOne("#blueprint");
    if (!nodesLayer) {
      return;
    }
    const layerSize = nodesLayer.getClientRect({
      relativeTo: stage,
    });

    // calculate sizes
    const stageSize = stage.getSize();
    const scaleX = stageSize.width / layerSize.width;
    const scaleY = stageSize.height / layerSize.height;
    const scaleValue = Math.min(scaleX, scaleY) * 1;

    // move canvas
    moveStage(stage, { x: 0, y: 20 }, { x: scaleValue, y: scaleValue }, onDone);
  };

  const moveStage = (stage, location, scale, onDone) => {
    const { x, y } = location;
    const tween = new Konva.Tween({
      duration: 0.35,
      easing: Konva.Easings.EaseInOut,
      node: stage,
      scaleX: (scale && scale.x) || 1,
      scaleY: (scale && scale.y) || 1,
      x,
      y,
    });

    tween.onFinish = tween.destroy;

    tween.play();
    stage.batchDraw();
    if (onDone) setTimeout(onDone, 2000);
  };

  const createNode = (json, row) => {
    let node = null;
    if (json.attrs.name === "Lamp") {
      const image = new Image(json.attrs.wdith, json.attrs.height);
      image.src = require("./tools/lightbulb.png");
      image.onload = () => {
        blueprintEditor.layer.batchDraw();
      };
      node = new Konva.Image({
        ...json,
        draggable:
          job && row.ID === job.ID && canUserEditElement(row, job, question),
        listening:
          job && row.ID === job.ID && canUserEditElement(row, job, question),
        image,
      });
    } else {
      node = Konva.Node.create({
        ...json,
        draggable:
          job && row.ID === job.ID && canUserEditElement(row, job, question),
        listening:
          job && row.ID === job.ID && canUserEditElement(row, job, question),
      });
      if (json.attrs.name === "Group") {
        node.clearCache();
        blueprintEditor.layer.add(node);
        node.cache();
      }
    }
    return node;
  };

  const onUpload = useCallback(
    async () => {
      var canvas = document.createElement("canvas");
      canvas.id = "pag" + filePage;
      canvas.className = "grande";
      const canvasContainer = document.getElementById("pdfContainer");
      var context = canvas.getContext("2d");
      const stageHeight =
        size && size.height ? size.height : window.innerHeight; /*  + 255 */
      const stageWidth =
        size && size.width ? size.width : window.innerWidth; /*  * proportion */
      canvas.height = stageHeight;
      canvas.width = stageWidth;
      const base_image = new Image();
      base_image.src = getFileTarget(imgSource, "lg");
      base_image.onload = function () {
        context.drawImage(base_image, 0, 0, stageWidth, stageHeight);
        canvasContainer.appendChild(canvas);
        dispatch(BlueprintEditor.setExportImage(canvas.toDataURL()));

        const bp = new Konva.Image({
          image: canvas,
          x: canvas.width / 2,
          y: canvas.height / 2,
          width: canvas.width,
          height: canvas.height,
          draggable: false,
          //listening: false,
          id: BLUEPRINT_ID,
          offset: {
            x: canvas.width / 2,
            y: canvas.height / 2,
          },
        });
        if (imageGrayscale) {
          bp.cache();
          bp.filters([Konva.Filters.Grayscale]);
        } else {
          bp.cache();
          bp.filters([]);
        }
        //bp.listening(false);
        blueprintEditor.layer.add(bp);
        blueprintEditor.layer.batchDraw();

        fitToScreen();
        if (
          blueprintEditor.app &&
          blueprintEditor.layer &&
          blueprintEditor.elements.length === 0
        ) {
          if (
            mode === "SET" &&
            blueprintEditor.measurements &&
            blueprintEditor.measurements.arrows
          ) {
            const { height, width } = blueprintEditor.measurements.arrows;
            if (height) {
              drawMeasure(height, "height", dispatch);
            }
            if (width) {
              drawMeasure(width, "width", dispatch);
            }
          }
          dispatch(BlueprintEditor.setMeasurementsChecked(true));
          getQuestionElements({
            questionId: question ? question._id : null,
            allJobs: question ? question.settings.showOtherJobs : true,
            jobId: job ? job._id : null,
            file: blueprintEditor.file,
          }).then((rows) => {
            if (rows.length === 0 && !showEditBpModal) {
              updateElements({
                question: question ? question._id : null,
                job: job ? job._id : null,
                elements: [],
                file: blueprintEditor.file,
                measurements: blueprintEditor.measurements,
              }).then(async (response) => {
                if (response && blueprintEditor.jobs.length === 0) {
                  const updatedJobs = await getQuestionElements({
                    questionId: question ? question._id : null,
                    allJobs: question ? question.settings.showOtherJobs : true,
                    jobId: job ? job._id : null,
                    file: blueprintEditor.file,
                  });
                  dispatch(BlueprintEditor.setJobs(updatedJobs));
                }
              });
            }
            dispatch(BlueprintEditor.setJobs(rows));
            const reduxElements = [];
            rows.forEach((row) => {
              const { elements } = row;
              elements.forEach(
                ({ canvasData, elementId, user, _id, visible, created_at }) => {
                  const json = JSON.parse(canvasData);
                  const node = createNode(json, row.job);
                  if (node) {
                    if (node.children && node.children.length > 0) {
                      //add event to childNode
                      const childNodeJson = json.children.find(
                        (child) =>
                          child.attrs && !child.attrs.globalCompositeOperation
                      );
                      const childNode = findShapeById(childNodeJson.attrs.id);
                      attachInitialEvents(
                        childNode,
                        row,
                        job,
                        question,
                        dispatch,
                        elementId,
                        visible,
                        user,
                        _id
                      );
                      reduxElements.push({
                        id: childNode.attrs.id,
                        //elementId: childNode.attrs.elementId,
                        elementId,
                        user,
                        visible,
                        created_at,
                        job: row.job,
                        question: row.question,
                      });
                    } else {
                      if (!json.attrs.groupId) {
                        attachInitialEvents(
                          node,
                          row,
                          job,
                          question,
                          dispatch,
                          elementId,
                          visible,
                          user,
                          _id
                        );
                        blueprintEditor.layer.add(node);
                        reduxElements.push({
                          id: _id,
                          elementId,
                          user,
                          visible,
                          created_at,
                          job: row.job,
                          question: row.question,
                        });
                      }
                    }
                    /* if (!json.attrs.groupId) {
                    } */
                  }
                }
              );
            });
            blueprintEditor.layer.batchDraw();
            dispatch(BlueprintEditor.setElements(reduxElements));
          });
        } else {
          if (blueprintEditor.elements && blueprintEditor.previousLayer) {
            blueprintEditor.elements.forEach((element) => {
              const filteredLayer = blueprintEditor.previousLayer.children.filter(
                (child) => child.attrs.id === element.id
              );
              const currentEl = filteredLayer[0];
              const { attrs } = currentEl;
              const name = attrs.name ? attrs.name : currentEl.constructor.name;
              const node = Konva.Node.create({
                attrs,
                className: name,
              });
              node.hitStrokeWidth(0);
              node.shadowForStrokeEnabled(false);
              if (!canUserEditElement(element.job, job, question)) {
                node.draggable(false);
              }
              node.on("dragstart", () => {
                const state = store.getState();
                const { blueprintEditor } = state;
                const { mode, selectedElement } = blueprintEditor;
                if (mode !== "EDIT" || !selectedElement) {
                  node.stopDrag();
                } else {
                  dispatch(BlueprintEditor.setSelectedElement(node));
                }
              });
              node.on("transform", () => {
                if (!canUserEditElement(element.job, job, question)) {
                  node.stopTransform();
                }
                if (
                  node.attrs &&
                  node.attrs.name &&
                  (node.attrs.name === CONSTRUCTOR_NAME.RECT ||
                    node.attrs.name === CONSTRUCTOR_NAME.CIRCLE ||
                    node.attrs.name === CONSTRUCTOR_NAME.LAMP)
                ) {
                  node.setAttrs({
                    width: Math.max(node.width() * node.scaleX(), 5),
                    height: Math.max(node.height() * node.scaleY(), 5),
                    scaleX: 1,
                    scaleY: 1,
                  });
                }
              });
              node.on("transformend", () => {
                if (
                  node.attrs &&
                  node.attrs.name &&
                  node.attrs.name === "Polygon"
                ) {
                  setScaledPoints(node);
                }
                dispatch(
                  BlueprintEditor.updateElement({
                    id: node.getAttr("id"),
                    elementId: element.id,
                    user: element.user,
                    visible: element.visible,
                    job: element.job,
                    question: element.question,
                  })
                );
                dispatch(BlueprintEditor.updateHistory());
              });
              node.on("dragend", () => {
                dispatch(
                  BlueprintEditor.updateElement({
                    id: node.getAttr("id"),
                    elementId: element.id,
                    user: element.user,
                    visible: element.visible,
                    job: element.job,
                    question: element.question,
                  })
                );
                dispatch(BlueprintEditor.updateHistory());
                //dispatch(BlueprintEditor.setSelectedElement(null));
              });
              blueprintEditor.layer.add(node);
              blueprintEditor.layer.batchDraw();
            });
          }
        }
      };
    },
    // eslint-disable-next-line
    [blueprintEditor, mode, dispatch, question, job]
  );

  const hideTooltip = () => {
    if (isMobileDevice()) {
      setTimeout(() => {
        ReactTooltip.hide();
      }, 2000);
    }
  };

  const onEditClick = () => {
    hideTooltip();
    dispatch(BlueprintEditor.changeMode("EDIT"));
    if (blueprintEditor.history.length === 0) {
      dispatch(BlueprintEditor.updateHistory());
    }
  };

  const onSetClick = () => {
    hideTooltip();
    dispatch(BlueprintEditor.changeMode("SET"));
    if (blueprintEditor.history.length === 0) {
      dispatch(BlueprintEditor.updateHistory());
    }
  };

  useEffect(
    () => {
      if (blueprintEditor.app && imgSource) {
        setTimeout(() => {
          onUpload(blueprintEditor.file);
        }, 500);
      }
    },
    // eslint-disable-next-line
    [blueprintEditor.file, blueprintEditor.app, imgSource, imageRotation]
  );

  useEffect(() => {
    if (blueprintEditor.app) {
      const node = blueprintEditor.app.findOne("#blueprint");
      if (node) {
        node.rotation(imageRotation);
        node.x(0);
        node.y(0);
        const { width, height } = Object.assign({
          width: blueprintEditor.app.width(),
          height: blueprintEditor.app.height(),
        });
        blueprintEditor.app.width(height);
        blueprintEditor.app.height(width);
        node.width(height);
        node.height(width);
        node.draw();
        blueprintEditor.app.batchDraw();
      }
    }
  }, [blueprintEditor.app, imageRotation]);

  const onCloseClick = () => {

    if (question) {
      saveQuestion().then(() => {
        onCloseCallback();
        dispatch(BlueprintEditor.reset());
      });
    } else {
      onCloseCallback();
      dispatch(BlueprintEditor.reset());
    }
  };

  const onViewClick = () => {
    hideTooltip();
    removeTransformers();
    dispatch(BlueprintEditor.changeMode("VIEW"));
  };

  const areMeasureArrowsVisible = () => {
    if (
      mode === "SET" &&
      blueprintEditor.measurements &&
      blueprintEditor.measurements.arrows &&
      blueprintEditor.measurements.arrows.height &&
      blueprintEditor.measurements.arrows.height.visible
    ) {
      return true;
    }
    return false;
  };

  const toggleArrowVisibility = (shape, el) => {
    if (shape) {
      if (el.visible) {
        shape.hide();
      } else {
        shape.show();
      }
      blueprintEditor.app.batchDraw();
    }
  };

  const toggleMeasureArrows = () => {
    hideTooltip();
    const currentMeasurements = { ...blueprintEditor.measurements };
    const { arrows } = currentMeasurements;
    //update visibility of arrows
    if (arrows) {
      const { height, width } = arrows;
      if (height) {
        const shape = blueprintEditor.app.find("#" + height.id)[0];
        toggleArrowVisibility(shape, height);
        currentMeasurements.arrows.height.visible = !height.visible;
      }
      if (width) {
        const shape = blueprintEditor.app.find("#" + width.id)[0];
        toggleArrowVisibility(shape, width);
        currentMeasurements.arrows.width.visible = !width.visible;
      }
    }
    //update project field
    updateFileProp("measurements", currentMeasurements, fileId);
  };

  const openJob = async (id) => {
    try {
      const currentJob = await getCurrentJob({ id }, "v2");
      dispatch(BlueprintEditor.setRightPanelJob(currentJob));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {pages ? (
        <AppBar position="static" className={classes.root}>
          <canvas style={{ display: "none" }} id="pdfContainer" />
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.centered}
            >
              <div>
                <IconButton onClick={() => onCloseClick()}>
                  <KeyboardBackspaceIcon />
                </IconButton>
                {question && <IconButton onClick={() => {
                  return activeQuestion + 1 !== filteredQuestions.length
                    ? nextQuestion()
                    : submitQuestion();
                }}>
                  <SaveIcon />
                </IconButton>}
              </div>
              <Typography
                style={{
                  cursor:
                    blueprintEditor.fileOptions.length > 1 &&
                    mode === "EDIT" &&
                    "pointer",
                }}
                onClick={() => {
                  if (
                    blueprintEditor.fileOptions.length > 1 &&
                    mode === "EDIT"
                  ) {
                    dispatch(BlueprintEditor.setFile(null));
                    const previousLayer = { ...blueprintEditor.layer };
                    dispatch(BlueprintEditor.setPreviuosLayer(previousLayer));
                  }
                }}
              >
                {fileName}
                {(mode === "EDIT" && markings > 1) && <Tooltip title={i18n.t('default:_MULTIPLE_MARKED_PLANS', {
                  count: markings
                })}>
                  <IconButton>
                    <WarningIcon className="warn-blink" />
                  </IconButton>
                </Tooltip>}
              </Typography>
              <Grid>
                <ReactTooltip id="topbar" effect="solid" />
                {showEditBpModal &&
                  canSet(user, settings) &&
                  areMeasuresSet(blueprintEditor.measurements) && (
                    <IconButton
                      onClick={() => toggleMeasureArrows()}
                      data-place="bottom"
                      data-tip={
                        areMeasureArrowsVisible()
                          ? i18n.t("default:_HIDE_MEASURE_ARROWS")
                          : i18n.t("default:_SHOW_MEASURE_ARROWS")
                      }
                      data-for="topbar"
                    >
                      <SwapHorizIcon />
                    </IconButton>
                  )}
                {showEditBpModal && canSet(user, settings) && (
                  <IconButton
                    onClick={() => onSetClick()}
                    data-place="bottom"
                    data-tip={i18n.t("default:_SET")}
                    data-for="topbar"
                  >
                    <WidgetsIcon
                      style={{
                        color: mode === "SET" && "lightgrey",
                        cursor: mode === "SET" && "default",
                      }}
                    />
                  </IconButton>
                )}
                {Object.keys(job).length > 0 && <IconButton
                  onClick={() => {
                    openJob(job._id);
                  }}
                  data-place="bottom"
                  data-tip={i18n.t("default:_VIEW_JOB")}
                  data-for="topbar"
                >
                  <WorkIcon className={classes.footerIcon} />
                </IconButton>}
                {canEdit() && (
                  <>
                    <IconButton
                      onClick={() => onViewClick()}
                      data-place="bottom"
                      data-tip={i18n.t("default:_VIEW")}
                      data-for="topbar"
                    >
                      <VisibilityIcon
                        style={{
                          color: mode === "VIEW" && "lightgrey",
                          cursor: mode === "VIEW" && "default",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => onEditClick()}
                      data-place="bottom"
                      data-tip={i18n.t("default:_EDIT")}
                      data-for="topbar"
                    >
                      <EditIcon
                        style={{
                          color: mode === "EDIT" && "lightgrey",
                          cursor: mode === "EDIT" && "default",
                        }}
                      />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </AppBar>
      ) : (
        <BigLoader />
      )}
    </>
  );
}

export default connectWithStore(BlueprintEditorTopbar, [
  "blueprintEditor",
  "user",
  "jobQuestions",
  "settings",
]);
