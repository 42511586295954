import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { connectWithStore } from "../../../services/redux";
import BlueprintEditorTopbar from "./topbar";
import BlueprintEditorCanvas from "./canvas";
import BlueprintEditorToolbar from "./toolbar";
import BlueprintEditorLayerPanel from "./layer_panel";
import PenTool from "./tools/pen_tool";
import PolygonTool from "./tools/polygon_tool";
import RectTool from "./tools/rect_tool";
import CircleTool from "./tools/circle_tool";
import LineTool from "./tools/line_tool";
import { PROJECT_FIELD_TYPE } from "../../../pages/projects/fields/constants";
import {
  CIRCLE_TOOL,
  LINE_TOOL,
  MEASURE_TOOL_VERTICAL,
  MEASURE_TOOL_HORIZONTAL,
  MOVE_TOOL,
  PEN_TOOL,
  RECT_TOOL,
  SHAPE,
  LAMP_TOOL,
  POLYGON_TOOL,
  POLYGON_OPEN,
  SCISSORS_TOOL,
} from "./constants";
import { BlueprintEditor } from "../../../redux/actions";
import SelectBlueprint from "./select_blueprint";
import i18n from "../../../i18n";
import {
  updateElements,
  getQuestionElements,
} from "../../../services/api/blueprints";
import MeasureToolHorizontal from "./tools/measure_tool_horizontal";
import MeasureToolVertical from "./tools/measure_tool_vertical";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import HeightInput from "./measure_inputs/hight_input";
import WidthInput from "./measure_inputs/width_input";
import WelcomeWizard from "./welcome_wizard";
import { ROLE } from "../../../services/roles";
import { getRgbaColor } from "./utils";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LampTool from "./tools/lamp_tool";
import Konva from "konva";
import { isMobileDevice } from "react-select/lib/utils";
import PdfExportWizard from "./pdf_export_wizard/pdf_export_wizard";
import withModal from "../../../hoc/withModal";
import JobEditorPanel from "../../jobs/job_editor_panel";
import ScissorsTool from "./tools/scissors_tool";
import { batch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
}));

//const scaleBy = 1.05;

const elementTransformer = (elem) => {
  const elemColor = getRgbaColor(elem.color);
  switch (elem.type) {
    case SHAPE.THIN_LINE:
      return {
        type: LINE_TOOL,
        width: elem.size,
        color: elemColor || "#000",
        name: elem.name,
        element: elem,
      };
    case SHAPE.THICK_LINE:
      return {
        type: LINE_TOOL,
        width: elem.size,
        color: elemColor || "#000",
        height: 2,
        name: elem.name,
        element: elem,
      };
    case SHAPE.RECT_FILLED:
      return {
        type: RECT_TOOL,
        width: elem.size,
        color: elemColor || "#000",
        name: elem.name,
        element: elem,
      };
    case SHAPE.RECT_OUTLINED:
      return {
        type: RECT_TOOL,
        width: elem.size,
        color: elemColor || "#000",
        outlined: true,
        name: elem.name,
        element: elem,
      };
    default:
    case SHAPE.CIRCLE_FILLED:
      return {
        type: CIRCLE_TOOL,
        width: elem.size,
        color: elemColor ? elemColor : "#000",
        name: elem.name,
        element: elem,
      };
    case SHAPE.CIRCLE_OUTLINED:
      return {
        type: CIRCLE_TOOL,
        width: elem.size,
        color: elemColor || "#000",
        outlined: true,
        name: elem.name,
        element: elem,
      };
    case SHAPE.DOODLE:
      return {
        type: PEN_TOOL,
        width: elem.size,
        color: elemColor || "#000",
        name: elem.name,
        element: elem,
      };
    case SHAPE.LAMP:
      return {
        type: LAMP_TOOL,
        width: elem.size,
        color: elem.color.hex || "#000",
        name: elem.name,
        element: elem,
      };
    case SHAPE.POLYGON_FILLED:
      return {
        type: POLYGON_TOOL,
        width: elem.size,
        color: elemColor || "#000",
        name: elem.name,
        element: elem,
      };
    case SHAPE.POLYGON_OUTLINED:
      return {
        type: POLYGON_TOOL,
        width: elem.size,
        outlined: true,
        color: elemColor || "#000",
        name: elem.name,
        element: elem,
      };
    case SHAPE.POLYGON_OPEN:
      return {
        type: POLYGON_OPEN,
        width: elem.size,
        outlined: false,
        color: elemColor || "#000",
        name: elem.name,
        element: elem,
      };
  }
};

function BPEditor(props) {
  const {
    job,
    blueprintEditor,
    settings,
    question,
    dispatch,
    handleChange,
    answers,
    filteredQuestions,
    submitQuestion,
    nextQuestion,
    saveQuestion,
    user,
    onClose
  } = props;
  const classes = useStyles();
  const {
    app,
    tool,
    measurementsChecked,
    mode,
    file,
    fileName,
    showEditBpModal,
    selectedElement,
    measureIntroDisplayed,
    rightPanelJob,
    pdfExportModalVisible,
    openPolygonSides,
  } = blueprintEditor;
  const { profile } = user;
  const { role } = profile;

  const [isSpacebarHeld, setIsSpacebarHeld] = useState(false);

  const timeout = useRef(null);

  useEffect(
    () => {
      if (answers && answers[question.ID] && answers[question.ID].value) {
        const fl = answers[question.ID].value
        let target = null;
        if (fl !== null) {
          const projectFields = settings.projectFields;
          let result = [];
          const bpFields = projectFields.filter(
            (f) => f.type === PROJECT_FIELD_TYPE.BLUEPRINT_LIST
          );
          bpFields.forEach((f) => {
            const bpList = job.project && job.project[f.id] ? job.project[f.id] : [];
            result = [...result, ...bpList.filter((bp) => bp.measurements)];
          });
          target = result.filter((t) => {
            const parts = t.location.split('?');
            return parts[0] === fl;
          })
        }
        batch(() => {

          if (target && target.length > 0) {
            dispatch(BlueprintEditor.setFile(fl));
            dispatch(BlueprintEditor.setFileName(target[0].value));
          }
        })

      }
    },
    // eslint-disable-next-line
    [question, answers]
  );

  useEffect(() => {
    dispatch(hideLoading());
  }, [fileName, dispatch]);

  useEffect(
    () => {
      const { project } = job;
      const { projectFields, blueprintSettings } = settings;
      const { elements } = blueprintSettings;
      if (!elements) {
        return;
      }
      const tools = [
        {
          type: MOVE_TOOL,
          color: "#000",
          name: i18n.t("default:_MOVE_TOOL"),
        },
        {
          type: MEASURE_TOOL_VERTICAL,
          color: "#000",
          name: i18n.t("default:_MEASURE_TOOL_VERTICAL"),
        },
        {
          type: MEASURE_TOOL_HORIZONTAL,
          color: "#000",
          name: i18n.t("default:_MEASURE_TOOL_HORIZONTAL"),
        },
        {
          type: SCISSORS_TOOL,
          color: "#000",
          name: i18n.t("default:_SCISSORS_TOOL"),
        },
      ];
      if (!question && !tool) {
        dispatch(BlueprintEditor.setTools(tools));
      }
      if (!question) {
        return;
      }
      const elementFields = projectFields.filter(
        (field) => field.type === PROJECT_FIELD_TYPE.BLUEPRINT_ELEMENTS
      );
      if (
        !question.settings.allElements &&
        elementFields.length > 0 &&
        project &&
        project[elementFields[0].id]
      ) {
        project[elementFields[0].id].forEach((element) => {
          const target =
            elements && elements.filter((e) => element.value === e.id);
          if (target.length > 0) {
            const tool = elementTransformer(target[0]);
            tools.push(tool);
          }
        });
      } else {
        elements.forEach((element) => {
          const tool = elementTransformer(element);
          tools.push(tool);
        });
      }
      dispatch(BlueprintEditor.setTools(tools));
      dispatch(BlueprintEditor.setQuestionOpen(true));
    },
    // eslint-disable-next-line
    [settings, job, question, tool]
  );

  useEffect(() => {
    if (isMobileDevice()) return;
    dispatch(showLoading());
    const dataElements = [];
    //update selected element from project or from other job
    const selectedElementInfo =
      (showEditBpModal ||
        (question && question.settings && question.settings.showOtherJobs)) &&
      selectedElement &&
      blueprintEditor.elements.filter(
        (el) => el.id === selectedElement.attrs.id
      )[0];

    const updatedJobNum =
      selectedElementInfo && selectedElementInfo.job
        ? selectedElementInfo.job.ID
        : job
          ? job.ID
          : null;
    const updatedJobId =
      selectedElementInfo && selectedElementInfo.job
        ? selectedElementInfo.job._id
        : job
          ? job._id
          : null;
    const updatedQuestionId = selectedElementInfo
      ? selectedElementInfo.question
      : question
        ? question._id
        : null;
    blueprintEditor.elements
      .filter((el) => (el.job ? el.job.ID === updatedJobNum : false))
      .filter((el) => (el.question ? el.question === updatedQuestionId : false))
      .forEach((el) => {
        const target = blueprintEditor.app.find("#" + el.id);
        if (target.length > 0) {
          const targetCanvasData = target.toJSON()[0];
          const groupId = targetCanvasData.attrs.groupId;
          let groupShape = null;
          if (groupId) {
            groupShape = blueprintEditor.app.find("#" + groupId);
          }
          dataElements.push({
            elementId: el.elementId,
            canvasData: groupShape ? groupShape.toJSON()[0] : targetCanvasData,
            visible: el.visible,
            job: updatedJobNum,
          });
        }
      });
    if (dataElements.length > 0) {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = setTimeout(() => {
        if (!updatedJobId) return;
        updateElements({
          question: updatedQuestionId,
          job: updatedJobId,
          elements: dataElements,
          file: blueprintEditor.file,
          measurements: blueprintEditor.measurements,
        }).then(async (response) => {
          if (response && blueprintEditor.jobs.length === 0) {
            const updatedJobs = await getQuestionElements({
              questionId: question._id,
              allJobs: question.settings.showOtherJobs,
              jobId: job._id,
              file: blueprintEditor.file,
            });
            dispatch(BlueprintEditor.setJobs(updatedJobs));
            //update id in elements with the received id / required because on creation the figure receives fake id
            const newElements = [...blueprintEditor.elements];
            if (newElements.length > 0 && updatedJobs) {
              const oldId = newElements[0].id;
              newElements[0].id = updatedJobs[0].elements[0]._id;
              newElements[0].initialId = oldId;
            }
            dispatch(BlueprintEditor.setElements(newElements));
          }
          timeout.current = null;
        });
      }, 1000);
    }
    dispatch(hideLoading());
    // eslint-disable-next-line
  }, [
    blueprintEditor.elements,
    blueprintEditor.app,
    blueprintEditor.file,
    blueprintEditor.jobs.length,
    blueprintEditor.measurements,
    question,
    job,
    dispatch,
    //selectedElement,
    showEditBpModal,
  ]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (blueprintEditor.app) {
      const bp = blueprintEditor.app.findOne("#blueprint");
      if (bp) {
        if (blueprintEditor.imageGrayscale) {
          bp.filters([Konva.Filters.Grayscale]);
        } else {
          bp.filters([]);
        }
        bp.cache();
        blueprintEditor.layer.batchDraw();
        blueprintEditor.layer.draw();
      }
    }
  }, [
    blueprintEditor.imageGrayscale,
    blueprintEditor.app,
    blueprintEditor.layer,
  ]);

  const renderPdfExportModal = () => {
    const PdfExportModal = withModal(PdfExportWizard);
    return (
      <PdfExportModal
        title="Pdf export"
        visible={pdfExportModalVisible}
        onClose={() => dispatch(BlueprintEditor.cancelCrop())}
      />
    );
  };


  useEffect(() => {
    const handleKeyDown = (event) => {
      const activeElement = document.activeElement;
      const inputs = ['input', 'select', 'button', 'textarea'];

      const isInput = activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1;
      if (event.code === 'Space' && !isInput) {
        event.preventDefault();
        setIsSpacebarHeld(true);
        window.isBluprintSpaceBarHeld = true;
      }
    };

    const handleKeyUp = (event) => {


      const activeElement = document.activeElement;
      const inputs = ['input', 'select', 'button', 'textarea'];

      const isInput = activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1;
      if (event.code === 'Space' && !isInput) {
        event.preventDefault();
        setIsSpacebarHeld(false);
        setTimeout(() => {
          window.isBluprintSpaceBarHeld = false;
        }, 500);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);
  let wrapperMoveDisabled = selectedElement ? true : false;
  if (tool && tool.type !== MOVE_TOOL) {
    wrapperMoveDisabled = true;
  }

  if (isSpacebarHeld) {
    wrapperMoveDisabled = false;
  }

  return (
    <React.Fragment>
      {pdfExportModalVisible && renderPdfExportModal()}
      {!file && (
        <SelectBlueprint
          job={job._id}
          project={job.project}
          settings={settings}
          onChange={(v) => handleChange(v)}
          answers={answers}
          question={question}
          submitQuestion={submitQuestion}
          nextQuestion={nextQuestion}
          filteredQuestions={filteredQuestions}
        />
      )}
      {file && (
        <TransformWrapper
          limitToBounds={false}
          minScale={0.1}
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
          wheel={{
            step: 0.05,
          }}
          //centerOnInit={true}
          disabled={
            wrapperMoveDisabled
          }
        >
          {({ setTransform }) => {
            return (
              <Grid className={classes.root}>
                <React.Fragment>
                  {measurementsChecked &&
                    !measureIntroDisplayed &&
                    mode === "SET" &&
                    role === ROLE.SYSTEM_ADMIN && (
                      <WelcomeWizard updateFileProp={props.updateFileProp} />
                    )}
                  <BlueprintEditorTopbar
                    job={job}
                    question={question}
                    submitQuestion={submitQuestion}
                    nextQuestion={nextQuestion}
                    saveQuestion={saveQuestion}
                    filteredQuestions={filteredQuestions}
                    setTransform={setTransform}
                    updateFileProp={props.updateFileProp}
                    onCloseCallback={onClose ? () => onClose() : null}
                  />
                  <TransformComponent
                    wrapperStyle={{
                      display: "flex",
                      justifyContent: "center",
                      background: "#ccc",
                      height:
                        isMobileDevice() && openPolygonSides > 1
                          ? window.innerHeight + 150
                          : window.innerHeight - 55,
                      width: window.innerWidth,
                    }}
                  >
                    <BlueprintEditorCanvas />
                  </TransformComponent>
                  <HeightInput updateFileProp={props.updateFileProp} />
                  <WidthInput updateFileProp={props.updateFileProp} />
                  <BlueprintEditorToolbar
                    updateFileProp={props.updateFileProp}
                    setTransform={setTransform}
                    job={job}
                  />
                  <BlueprintEditorLayerPanel job={job} question={question} />
                  {app && <PenTool job={job} question={question} />}
                  {app && <PolygonTool isSpacebarHeld={isSpacebarHeld} job={job} question={question} />}
                  {app && <RectTool job={job} question={question} />}
                  {app && <CircleTool job={job} question={question} />}
                  {app && <LineTool job={job} question={question} />}
                  {/* {app && <MoveTool />} */}
                  {app && <LampTool job={job} question={question} />}
                  {app && <MeasureToolVertical />}
                  {app && <MeasureToolHorizontal />}
                  {app && <ScissorsTool job={job} />}
                </React.Fragment>
                {rightPanelJob && (
                  <JobEditorPanel
                    panelStyles={{ position: "absolute", top: 0, right: 0 }}
                    onDelete={() =>
                      dispatch(BlueprintEditor.setRightPanelJob(null))
                    }
                    onClose={() =>
                      dispatch(BlueprintEditor.setRightPanelJob(null))
                    }
                    rightPanelJob={rightPanelJob}
                    fromBlueprints={true}
                  />
                )}
              </Grid>
            );
          }}
        </TransformWrapper>
      )}
    </React.Fragment>
  );
}

export default connectWithStore(BPEditor, [
  "blueprintEditor",
  "settings",
  "user",
]);
