import React, { useEffect, useRef } from "react";
import Konva from "konva";
import { connectWithStore } from "../../../../services/redux";
import { MOVE_TOOL, PEN_TOOL } from "../constants";
import { BlueprintEditor } from "../../../../redux/actions";
import store from "../../../../redux/store";
import {
  addNewElementToJob,
  getRelativePointerPosition,
  canUserEditElement,
} from "../utils";
import { v1 as uuid } from "uuid";
import { batch } from "react-redux";
import i18n from "../../../../i18n";
import { isMobileDevice } from "../../../../services/mobile";

let lastLine;
let isPaint;

const ELEMENT_NAME = "Doodle";

function PenTool({ blueprintEditor, dispatch, job, question }) {
  const { app } = blueprintEditor;
  let transformer = useRef();

  useEffect(() => {
    if (app) {
      app.on("mousedown touchstart", (e) => {
        const state = store.getState();
        const { blueprintEditor, user } = state;
        const { tool, layer, mode } = blueprintEditor;
        const { profile } = user;

        if (mode !== "EDIT") {
          return;
        }
        if (
          !tool ||
          (tool && tool.type !== MOVE_TOOL && tool.type !== PEN_TOOL)
        ) {
          return;
        }
        if (
          transformer.current &&
          transformer.current._nodes &&
          transformer.current._nodes.length > 0
        ) {
          transformer.current.nodes([]);
          layer.batchDraw();
          dispatch(BlueprintEditor.setSelectedElement(null));
          dispatch(BlueprintEditor.setElementsInfoPanelOpen(false));
          dispatch(BlueprintEditor.setDimensionsPanel(false));
          //return;
        }
        if (!transformer.current) {
          transformer.current = new Konva.Transformer();
          layer.add(transformer.current);
        }
        if (
          (e.target === app || e.target.id() === "blueprint" || (e.target.parent && e.target.parent.attrs.name && e.target.parent.attrs.name.includes("Layer"))) &&
          tool &&
          tool.type === PEN_TOOL
        ) {
          if (
            transformer.current &&
            (!transformer.current._nodes ||
              transformer.current._nodes.length === 0)
          ) {
            isPaint = true;
            var pos = getRelativePointerPosition(app);
            const id = uuid();
            const canvasData = {
              stroke: tool.color,
              strokeWidth: tool.width,
              globalCompositeOperation: "source-over",
              points: [pos.x, pos.y],
              draggable: true,
              name: ELEMENT_NAME,
              id,
            };
            lastLine = new Konva.Line(canvasData);
            const updatedJobs = addNewElementToJob(
              canvasData,
              ELEMENT_NAME,
              tool,
              id,
              blueprintEditor
            );
            dispatch(BlueprintEditor.setJobs(updatedJobs));
            layer.add(lastLine);
            dispatch(BlueprintEditor.setSelectedElement(lastLine));
            layer.batchDraw();
            dispatch(
              BlueprintEditor.addElement({
                id,
                elementId: tool.element.id,
                user: profile,
                job,
              })
            );
            lastLine.on("dragstart", () => {
              if (
                blueprintEditor.mode !== "EDIT" ||
                !(
                  transformer &&
                  transformer.current &&
                  transformer.current._nodes &&
                  transformer.current._nodes.length > 0
                )
              ) {
                lastLine.stopDrag();
              } else {
                dispatch(BlueprintEditor.setSelectedElement(lastLine));
              }
            });
            lastLine.on("dragend", () => {
              dispatch(
                BlueprintEditor.updateElement({
                  id: lastLine.getAttr("id"),
                  elementId: tool.element.id,
                  user: profile,
                  visible: true,
                  job,
                })
              );
              dispatch(BlueprintEditor.updateHistory());
              //dispatch(BlueprintEditor.setSelectedElement(null));
            });
          }
        }
        if (
          e.target &&
          e.target.attrs &&
          e.target.attrs.name === "PEN_TOOL" &&
          transformer.current
        ) {
          const selectedElementArr = blueprintEditor.elements.filter(
            (el) => el.id === e.target.attrs.id
          );
          const elementJob =
            selectedElementArr.length > 0 ? selectedElementArr[0].job : null;
          if (!canUserEditElement(elementJob, job, question)) {
            return;
          }
          if (!isMobileDevice()) {
            dispatch(BlueprintEditor.selectLine(e.target));
          }
          transformer.current.nodes([e.target]);
          layer.batchDraw();
          return;
        }

        if (
          transformer.current &&
          transformer.current._nodes &&
          transformer.current._nodes.length > 0
        ) {
          dispatch(BlueprintEditor.deselectElement());
          transformer.current.nodes([]);
          layer.batchDraw();
          return;
        }
      });

      app.on("mouseup touchend", () => {
        const state = store.getState();
        const { blueprintEditor, user } = state;
        const { tool } = blueprintEditor;
        const { profile } = user;
        if (!tool || (tool && tool.type !== PEN_TOOL)) {
          return;
        }
        isPaint = false;

        blueprintEditor.app.draggable(true);
        batch(() => {
          dispatch(
            BlueprintEditor.changeTool({
              type: MOVE_TOOL,
              color: "#000",
              name: i18n.t("default:_MOVE_TOOL"),
            })
          );
          dispatch(
            BlueprintEditor.updateHistory(
              tool && tool.element && tool.element.id
            )
          );
          dispatch(
            BlueprintEditor.updateElement({
              id: lastLine.getAttr("id"),
              elementId: tool.element.id,
              user: profile,
              visible: true,
              job,
            })
          );
        });
      });

      // and core function - drawing
      app.on("mousemove touchmove", () => {
        const state = store.getState();
        const { blueprintEditor } = state;
        const { tool, layer } = blueprintEditor;
        if (tool && tool.type !== PEN_TOOL) {
          return;
        }
        blueprintEditor.app.draggable(false);
        if (!isPaint) {
          return;
        }

        const pos = getRelativePointerPosition(app);
        var newPoints = lastLine.points().concat([pos.x, pos.y]);

        lastLine.points(newPoints);
        layer.batchDraw();
      });
    }
    // eslint-disable-next-line
  }, [app, dispatch]);
  return <div />;
}

export default connectWithStore(PenTool, ["blueprintEditor"]);
