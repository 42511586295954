import Http from "../http";

export const getQuestionElements = async ({
  questionId,
  jobId,
  file,
  allJobs,
}) => {
  const path = `/v2/blueprints`;
  const params = {
    file,
  };
  if (!allJobs) {
    params["job"] = jobId;
    params["question"] = questionId;
  }
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getMarkings = async (params) => {
  const path = `/v2/blueprints/markings`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const updateElements = async ({
  question,
  job,
  file,
  elements,
  measurements,
}) => {
  if (!job) {
    throw new Error("Please supply job to updateElements http call");
  }
  const path = `/v2/blueprints/${job}/${question}`;
  const params = {
    question,
    file,
    job,
    measurements: { ...measurements },
    elements: elements.filter((el) => typeof el.elementId !== "undefined"),
  };
  try {
    const response = await Http.sharedInstance.withAuth().put(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const exportBlueprintPdf = async ({
  blueprintImage,
  elements,
  size,
}) => {
  const path = `/v1/jobs/blueprint/export`;
  const params = { blueprintImage, elements, size };
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const deleteElement = async (id) => {
  const path = `/v2/blueprints/element/${id}`;
  try {
    const response = await Http.sharedInstance.withAuth().delete(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

export const exportMarkingsXslx = async (params) => {
  const path = `/v2/blueprints/markings/export`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params, {
      responseType: "blob", // Ensure response is a Blob (binary)
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};
